import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import i18n from "./i18n";
import axios from "axios";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";

axios.defaults.baseURL = process.env.VUE_APP_SERVER_URL;
loadFonts();
const pinia = createPinia();

createApp(App)
  .use(Quasar, quasarUserOptions)
  .use(pinia)
  .use(i18n)
  .use(router)
  .use(vuetify)
  .mount("#app");
