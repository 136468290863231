export enum PlanTags {
  FULL = "full",
  TIME = "time",
}

export enum PlansTypes {
  LIFETIME = "lifetime",
  SUBSCRIPTION = "subscription",
}

interface PlanPattern {
  type: PlansTypes;
  singlePrice?: number;
  dailyPrice?: number;
  comment: string;
  additionalKeyPrice: number;
}

export type GridItem = {
  tag: PlanTags;
  plan: PlanPattern;
};
