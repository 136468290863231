export default {
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "login-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "captcha-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Captcha Error"])},
  "server-err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server error"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify"])},
  "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exchange"])},
  "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
  "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full"])},
  "broker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broker"])},
  "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
  "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription"])},
  "trades-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trades count"])},
  "no-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No plan"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your balance:"])},
  "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan:"])},
  "your-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your plan:"])},
  "select-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a plan"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["day"])},
  "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["daily"])},
  "once": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["once"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
  "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["until"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
  "subscribers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribers"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
  "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
  "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
  "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
  "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom"])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
  "linked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linked"])},
  "click-to-copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to copy"])},
  "copied-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied successfully"])},
  "partially-paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partially paid"])},
  "to-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to bot"])},
  "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market"])},
  "futures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Futures"])},
  "spot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spot"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subscribe"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancel"])},
  "cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cost"])},
  "login-card": {
    "email-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email can not be empty"])},
    "email-must-be-valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email must be valid"])},
    "password-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password can not be empty"])},
    "restore-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore password"])},
    "not-registered-yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not registered yet?"])},
    "register-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register now!"])},
    "incorrect-email-or-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect email or password"])}
  },
  "register-card": {
    "registration-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration error"])},
    "email-already-registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email already registered"])},
    "register-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration"])},
    "repeat-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])},
    "passwords-doesnt-match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords doesn't match"])},
    "password-greater-than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords must contain 8-32 symbols"])},
    "already-registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already registered?"])}
  },
  "forgot-card": {
    "restore-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore password"])},
    "user-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address not registered yet"])},
    "restore-mail-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore mail sent! Check your mailbox"])},
    "restore-failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore failed. Incorrect or expired link"])},
    "password-updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password successfully updated. You will be redirected to main page in 5 seconds"])}
  },
  "confirm-email-card": {
    "enter-code-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the code received by e-mail"])},
    "confirmation-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid or expired code"])}
  },
  "key-control": {
    "key-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Management"])},
    "api-key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Key"])},
    "api-secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API secret"])},
    "registration-after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account registration date on binance must be after 21.01.2022"])},
    "not-ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main account must not be reffered"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration"])},
    "ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ref"])},
    "edit-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully edited"])},
    "delete-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully deleted"])},
    "key-added-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key added. Type: "])},
    "prepaid-keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available prepaid keys"])},
    "key-add-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key add error. Please reload page."])},
    "key-exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exceeded key limit, or key already exists."])},
    "delete-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to remove this API key?"])}
  },
  "plan-store": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully bought"])},
    "days-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days count"])},
    "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkout"])},
    "insufficient-balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient balance"])},
    "subs-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available until"])},
    "plan-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan management"])},
    "days-select-tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter number of days for subscription renewal"])}
  },
  "balance-republish": {
    "balance-republish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["balance replenishment"])},
    "valid-until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid until"])},
    "payment-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment address"])},
    "select-currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select currency"])},
    "enter-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter amount"])},
    "create-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create payment"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take into account the commission when sending. The deposit will only be credited to your account if the sent amount is not less than the amount specified in the payment. If you send more, the entire sent amount will be credited. If you send less, <span class=\"text-decoration-underline text-h6 text-uppercase\">DO NOT</span> send a second transaction. It will be lost."])}
  },
  "payments-table": {
    "invoiceUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoce URL"])}
  },
  "add-keys-store": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional keys purchase"])},
    "dialog-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will increase the limit of paid keys by 1. If you have the TIME subscription active, the remaining period will be recalculated. You can reduce the limit of keys only by contacting the administration."])},
    "one-key-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cost of 1 additional key"])},
    "keys-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your current ley limit:"])}
  },
  "profile-view": {
    "tg-linking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram linking"])},
    "tg-fail-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If Telegram not linked automatically, send"])}
  },
  "signals-control": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signals control"])},
    "my-signals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My signals"])},
    "new-signal-creating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating a new signal"])},
    "new-signal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New signal"])},
    "signal-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signal description"])},
    "signal-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signal name"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully created"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error during signal creation"])},
    "error-loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error during signals loading"])},
    "webhook-setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook setting"])},
    "last-signals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last signals"])},
    "setting-attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention! Change the side fields according to your strategy (LONG/SHORT), timeframe field (according to the options in program) and the lifetime (relevant field) in seconds"])},
    "available-signals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available signals"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this signal?"])},
    "delete-binding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure want to unsubscribe this key from this signal?"])},
    "private-signal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signal is private"])},
    "public-signal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signal is public"])},
    "check-signal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check"])}
  },
  "marketplace": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketplace"])},
    "configs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurations"])},
    "signals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signals"])},
    "my-purchases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My purchases"])},
    "is-selling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is selling?"])},
    "subscribe-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe to"])},
    "subscribe-duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe duration, in days"])},
    "subscribe-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription cost"])},
    "your-subs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is your signal. Subscription is free."])},
    "confirm-purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm purchase?"])},
    "renew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renew"])},
    "signal-renewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signal renewing"])},
    "renew-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renew error"])},
    "renew-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renewed successfully"])}
  }
}