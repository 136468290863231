export default {
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход"])},
  "login-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логин"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])},
  "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Восстановление"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
  "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бесплатно"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выход"])},
  "captcha-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка капчи"])},
  "server-err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка сервера"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])},
  "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверить"])},
  "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Биржа"])},
  "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заметка"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
  "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full"])},
  "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купить"])},
  "broker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брокер"])},
  "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создан"])},
  "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
  "trades-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество сделок"])},
  "no-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет тарифа"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш баланс:"])},
  "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тариф:"])},
  "your-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш тариф:"])},
  "select-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите тариф"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["день"])},
  "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ежедневно"])},
  "once": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["однократно"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена"])},
  "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["до"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
  "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершено"])},
  "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Истекло"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидание"])},
  "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дней"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месяц"])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["от"])},
  "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["настроить"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль"])},
  "click-to-copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нажмите что бы скопировать"])},
  "partially-paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частично оплачен"])},
  "copied-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно скопировано"])},
  "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Привязать"])},
  "linked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Привязано"])},
  "to-bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["боту"])},
  "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рынок"])},
  "futures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фьючерс"])},
  "spot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спот"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
  "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписаться"])},
  "subscribers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписчики"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
  "cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стоимость"])},
  "login-card": {
    "email-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email не может быть пустым"])},
    "email-must-be-valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email должен быть действительным"])},
    "password-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль не может быть пустым"])},
    "restore-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Восстановление пароля"])},
    "not-registered-yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Еще не зарегистрированы?"])},
    "register-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрироваться сейчас!"])},
    "incorrect-email-or-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный email или пароль"])}
  },
  "register-card": {
    "registration-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка регистрации"])},
    "email-already-registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email уже зарегистрирован"])},
    "register-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])},
    "repeat-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторите пароль"])},
    "passwords-doesnt-match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароли не совпадают"])},
    "password-greater-than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль должен содержать от 8 до 32 символов"])},
    "already-registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уже зарегистрированы?"])}
  },
  "forgot-card": {
    "restore-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Восстановление пароля"])},
    "user-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес электронной почты не зарегистрирован"])},
    "restore-mail-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Письмо для восстановления отправлено! Проверьте вашу почту"])},
    "restore-failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка восстановления. Неверная или просроченная ссылка"])},
    "password-updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль успешно обновлен. Вы будете перенаправлены на главную страницу через 5 секунд"])}
  },
  "confirm-email-card": {
    "enter-code-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите код, полученный по электронной почте"])},
    "confirmation-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный или просроченный код"])}
  },
  "key-control": {
    "key-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление API"])},
    "api-key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API ключ"])},
    "api-secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API секрет"])},
    "registration-after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата регистрации аккаунта на бирже binance должна быть после 21.01.2022"])},
    "not-ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основной аккаунт не должен быть реферальным"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])},
    "ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реф"])},
    "prepaid-keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно платных ключей"])},
    "edit-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно отредактировано"])},
    "delete-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно удалено"])},
    "key-added-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ключ добавлен. Тип: "])},
    "key-add-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка добавления ключа. Пожалуйста, перезагрузите страницу."])},
    "key-exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Превышен лимит ключей или ключ уже существует."])},
    "delete-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены, что хотите удалить этот API ключ?"])}
  },
  "plan-store": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно куплено"])},
    "days-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество дней"])},
    "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплатить"])},
    "insufficient-balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недостаточно средств"])},
    "subs-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно до"])},
    "plan-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление тарифом"])},
    "days-select-tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите количество дней для продления подписки"])}
  },
  "balance-republish": {
    "balance-republish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пополнение баланса"])},
    "valid-until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно до"])},
    "payment-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адресс оплаты"])},
    "select-currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите валюту"])},
    "enter-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите количество"])},
    "create-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать счет"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учитывайте комиссию при отправке и <span class=\"text-decoration-underline text-h6 text-uppercase\">НЕ</span> отправляйте перевод повторно, даже, если сумма меньше заявленной. На счет будет зачислена вся сумма. "])}
  },
  "payments-table": {
    "invoiceUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка на счет-фактуру"])}
  },
  "add-keys-store": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покупка дополнительных ключей"])},
    "dialog-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это увеличит лимит платных ключей на 1. Если у вас активен тариф ТАЙМ, то оставшийся срок будет перерасчитан. Уменьшить лимит ключей можно только через обращение к администрации."])},
    "one-key-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стоимость 1 дополнительного ключа"])},
    "keys-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш текущий лимит ключей:"])}
  },
  "profile-view": {
    "tg-linking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Привязка телеграма"])},
    "tg-fail-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если не удалось автоматически привязать телеграм, отправте"])}
  },
  "signals-control": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление сигналами"])},
    "my-signals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои сигналы"])},
    "new-signal-creating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание нового сигнала"])},
    "new-signal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый сигнал"])},
    "signal-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание сигнала"])},
    "signal-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название сигнала"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно создано"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка во время создания сигнала"])},
    "error-loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка во время загрузки сигналов"])},
    "webhook-setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки вебхука"])},
    "last-signals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последние сигналы"])},
    "setting-attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внимание! Измените поля side в соответствии с вашей стратегией (LONG/SHORT), поле timeframe (согласно вариантам в программе) и время жизни relevant в секундах"])},
    "available-signals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступные сигналы"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы точно хотите удалить этот сигнал?"])},
    "delete-binding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы точно хотите отписать этот ключ от этого сигнала?"])},
    "private-signal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приватный сигнал"])},
    "public-signal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публичный сигнал"])},
    "check-signal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть"])}
  },
  "marketplace": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маркетплейс"])},
    "configs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конфигурации"])},
    "signals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сигналы"])},
    "my-purchases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои покупки"])},
    "error-loading-my": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка во время загрузки моих сигналов"])},
    "is-selling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сигнал продается?"])},
    "subscribe-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписка на "])},
    "subscribe-duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Длительность подписки, в днях"])},
    "subscribe-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стоимость подписки"])},
    "your-subs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это Ваш сигнал. Подписка бесплатна."])},
    "confirm-purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждаете покупку?"])},
    "renew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продлить"])},
    "signal-renewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продление сигнала"])},
    "renew-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка продления"])},
    "renew-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продлено успешно"])},
    "link-key-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ключ успешно привязан"])},
    "link-key-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка привязки ключа"])}
  }
}