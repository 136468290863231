import axios from "axios";

class TelegramApi {
  async getLink(): Promise<string> {
    const res = await axios.get<string>("/telegram/link");
    return res.data;
  }
}

export default new TelegramApi();
