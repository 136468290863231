import axios from "axios";
import { User } from "@/api/user.types";

class UserApi {
  async getInfo(): Promise<User> {
    const res = await axios.get<User>("/users/info");
    res.data.balance = Number(res.data.balance.toFixed(2));
    return res.data;
  }
}

export default new UserApi();
