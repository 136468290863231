import axios from "axios";
import { NewPayment, Payment, PaymentInfo } from "@/api/payments.types";

class SubscriptionApi {
  async getPayments() {
    const res = await axios.get<Payment[]>("/payments");
    return res.data;
  }
  async createPayment(info: PaymentInfo) {
    const res = await axios.post<NewPayment>("/payments/createPayment", info);
    return res.data;
  }
}
export default new SubscriptionApi();
