import axios from "axios";
import {
  BindKeyBody,
  BuySignalBody,
  CreateSignalRequest,
  EditSignalRequest,
  MySignalResponse,
  PurchasedSignal,
  RenewSignalBody,
  SignalResponse,
} from "@/api/marketplace.types";
import { SimpleSuccess } from "@/api/auth.types";

class MarketplaceApi {
  async getAllSignals() {
    const res = await axios.get<SignalResponse[]>("/marketplace/signals");
    return res.data;
  }
  async getMySignals() {
    const res = await axios.get<MySignalResponse[]>("/marketplace/signals/my");
    return res.data;
  }

  async getPurchasedSignals() {
    const res = await axios.get<PurchasedSignal[]>(
      "/marketplace/signals/purchased"
    );
    return res.data;
  }

  async createSignal(create: CreateSignalRequest) {
    const res = await axios.post<SimpleSuccess>(
      "/marketplace/signals/new",
      create
    );
    return res.data;
  }

  async editSignal(edit: EditSignalRequest) {
    const res = await axios.post<SimpleSuccess>(
      "/marketplace/signals/edit",
      edit
    );
    return res.data;
  }

  async deleteSignal(id: string) {
    const res = await axios.post<SimpleSuccess>("/marketplace/signals/delete", {
      id,
    });
    return res.data;
  }

  async buySignal(purchase: BuySignalBody) {
    const res = await axios.post<SimpleSuccess>("/marketplace/signals/buy", {
      ...purchase,
    });
    return res.data;
  }

  async renewSignal(renew: RenewSignalBody) {
    const res = await axios.post<SimpleSuccess>(
      "/marketplace/signals/renew",
      renew
    );
    return res.data;
  }

  async bindKey(bind: BindKeyBody) {
    const res = await axios.post<SimpleSuccess>(
      "/marketplace/signals/bindKey",
      {
        ...bind,
      }
    );
    return res.data;
  }
}
export default new MarketplaceApi();
