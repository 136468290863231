export enum RolesType {
  "USER" = "user",
  "ADMINISTRATOR" = "administrator",
  "MODERATOR" = "moderator",
  "PAID" = "paid",
  "SUBS" = "subscriber",
  "TESTER" = "tester",
}

export interface User {
  id: string;
  email: string;
  balance: number;
  paidKeysLimit: number;
  subscribeExpiration: Date;
  role: RolesType[];
  verified?: boolean;
  tgLinked: boolean;
}
export interface Telegram {
  linked: boolean;
  link: string;
  userInfo: {
    first_name: string;
    username?: string;
  };
}
