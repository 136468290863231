import axios from "axios";
import {
  AvailableRouter,
  BindKeyRequest,
  CreateRouter,
  EditRouter,
  GetMySubscriptionRes,
  SignalRouter,
  SignalsFromRouter,
} from "@/api/signals.types";
import { SimpleSuccess } from "@/api/auth.types";

class SignalsApi {
  async getMySignals(): Promise<SignalRouter[]> {
    const res = await axios.get<SignalRouter[]>("/trading-signals/");
    return res.data;
  }

  async createNewRouter(router: CreateRouter): Promise<SignalRouter> {
    const res = await axios.post<SignalRouter>(
      "/trading-signals/create",
      router
    );
    return res.data;
  }

  async editRouter(edit: EditRouter): Promise<void> {
    await axios.post("/trading-signals/edit", edit);
  }

  async deleteRouter(routerId: string): Promise<SimpleSuccess> {
    const res = await axios.post<SimpleSuccess>("/trading-signals/delete", {
      routerId,
    });
    return res.data;
  }

  async getSignalsFromRouter(routerId: string): Promise<SignalsFromRouter[]> {
    const res = await axios.post<SignalsFromRouter[]>(
      "/trading-signals/getSignals",
      { routerId }
    );
    return res.data;
  }

  async getAllAvailableRouters() {
    const res = await axios.get<AvailableRouter[]>(
      "/trading-signals/allAvailableRouters"
    );
    return res.data;
  }

  async bindKey(binding: BindKeyRequest) {
    const res = await axios.post<SimpleSuccess>(
      "/trading-signals/bindKey",
      binding
    );
    return res.data;
  }

  async removeBinding(binding: BindKeyRequest) {
    const res = await axios.post<SimpleSuccess>(
      "/trading-signals/removeBinding",
      binding
    );
    return res.data;
  }

  async getMySubscription() {
    const res = await axios.get<GetMySubscriptionRes[]>(
      "/trading-signals/getMySubscription"
    );
    return res.data;
  }
}

export default new SignalsApi();
