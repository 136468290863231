import { RolesType, User } from "@/api/user.types";
import i18n from "../i18n";
import Notify from 'quasar/src/plugins/Notify.js';;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const { t } = i18n.global;
export const getPlan = (
  user: User
): { text: string; type: RolesType | undefined } => {
  if (!user) return { text: t("no-plan"), type: undefined };
  if (user.role.includes(RolesType.PAID))
    return { text: t("paid"), type: RolesType.PAID };
  const subsEndDate = new Date(user.subscribeExpiration).getTime();
  if (user.role.includes(RolesType.SUBS) && Date.now() < subsEndDate)
    return { text: t("subscription"), type: RolesType.SUBS };
  return { text: t("no-plan"), type: undefined };
};

export const isUserSub = (user: User) => {
  const subsEndDate = new Date(user.subscribeExpiration).getTime();
  return (
    user.role.includes(RolesType.SUBS) &&
    !user.role.includes(RolesType.PAID) &&
    Date.now() < subsEndDate
  );
};

export const getSubscriptionUntil = (user: User) => {
  return `(${t("until")} ${new Date(
    user.subscribeExpiration || 0
  ).toLocaleDateString()})`;
};

export const showNegativeNotify = (text: string) => {
  Notify.create({
    type: "negative",
    message: text,
    position: "top",
    icon: undefined,
  });
};

export const showPositiveNotify = (text: string) => {
  Notify.create({
    type: "positive",
    message: text,
    position: "top",
    icon: undefined,
  });
};
