<template>
  <div>
    <template v-for="(item, index) in items">
      <div
        class="divider"
        v-if="item.type === 'divider'"
        :key="`divider${index}`"
      />
      <menu-item
        v-else
        :key="index"
        :action="item.action"
        :icon="item.icon"
        :is-active="item.isActive"
        :title="item.title"
      />
    </template>
  </div>
</template>

<script>
import MenuItem from "./editor-menu-item.vue";

export default {
  components: {
    MenuItem,
  },

  props: {
    editor: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      items: [
        {
          icon: "mdi-format-bold",
          title: "Bold",
          action: () => this.editor.chain().focus().toggleBold().run(),
          isActive: () => this.editor.isActive("bold"),
        },
        {
          icon: "mdi-format-italic",
          title: "Italic",
          action: () => this.editor.chain().focus().toggleItalic().run(),
          isActive: () => this.editor.isActive("italic"),
        },
        {
          icon: "mdi-format-strikethrough",
          title: "Strike",
          action: () => this.editor.chain().focus().toggleStrike().run(),
          isActive: () => this.editor.isActive("strike"),
        },
        // {
        //   icon: "code-view",
        //   title: "Code",
        //   action: () => this.editor.chain().focus().toggleCode().run(),
        //   isActive: () => this.editor.isActive("code"),
        // },
        {
          icon: "mdi-marker",
          title: "Highlight",
          action: () => this.editor.chain().focus().toggleHighlight().run(),
          isActive: () => this.editor.isActive("highlight"),
        },
        {
          type: "divider",
        },
        {
          icon: "mdi-format-header-1",
          title: "Heading 1",
          action: () =>
            this.editor.chain().focus().toggleHeading({ level: 1 }).run(),
          isActive: () => this.editor.isActive("heading", { level: 1 }),
        },
        {
          icon: "mdi-format-header-2",
          title: "Heading 2",
          action: () =>
            this.editor.chain().focus().toggleHeading({ level: 2 }).run(),
          isActive: () => this.editor.isActive("heading", { level: 2 }),
        },
        {
          icon: "mdi-format-paragraph",
          title: "Paragraph",
          action: () => this.editor.chain().focus().setParagraph().run(),
          isActive: () => this.editor.isActive("paragraph"),
        },
        {
          icon: "mdi-format-list-bulleted",
          title: "Bullet List",
          action: () => this.editor.chain().focus().toggleBulletList().run(),
          isActive: () => this.editor.isActive("bulletList"),
        },
        {
          icon: "mdi-format-list-numbered",
          title: "Ordered List",
          action: () => this.editor.chain().focus().toggleOrderedList().run(),
          isActive: () => this.editor.isActive("orderedList"),
        },
        {
          icon: "mdi-format-list-checkbox",
          title: "Task List",
          action: () => this.editor.chain().focus().toggleTaskList().run(),
          isActive: () => this.editor.isActive("taskList"),
        },
        // {
        //   icon: "code-box-line",
        //   title: "Code Block",
        //   action: () => this.editor.chain().focus().toggleCodeBlock().run(),
        //   isActive: () => this.editor.isActive("codeBlock"),
        // },
        {
          type: "divider",
        },
        {
          icon: "mdi-format-quote-close",
          title: "Blockquote",
          action: () => this.editor.chain().focus().toggleBlockquote().run(),
          isActive: () => this.editor.isActive("blockquote"),
        },
        {
          icon: "mdi-minus",
          title: "Horizontal Rule",
          action: () => this.editor.chain().focus().setHorizontalRule().run(),
        },
        {
          type: "divider",
        },
        {
          icon: "mdi-format-text-wrapping-wrap",
          title: "Hard Break",
          action: () => this.editor.chain().focus().setHardBreak().run(),
        },
        {
          icon: "mdi-format-clear",
          title: "Clear Format",
          action: () =>
            this.editor.chain().focus().clearNodes().unsetAllMarks().run(),
        },
        {
          type: "divider",
        },
        {
          icon: "mdi-format-align-left",
          title: "Left",
          action: () => this.editor.chain().focus().setTextAlign("left").run(),
        },
        {
          icon: "mdi-format-align-right",
          title: "Right",
          action: () => this.editor.chain().focus().setTextAlign("right").run(),
        },
        {
          icon: "mdi-format-align-center",
          title: "Center",
          action: () =>
            this.editor.chain().focus().setTextAlign("center").run(),
        },
        {
          icon: "mdi-format-align-justify",
          title: "Justify",
          action: () =>
            this.editor.chain().focus().setTextAlign("justify").run(),
        },
        {
          type: "divider",
        },
        {
          icon: "mdi-undo",
          title: "Undo",
          action: () => this.editor.chain().focus().undo().run(),
        },
        {
          icon: "mdi-redo",
          title: "Redo",
          action: () => this.editor.chain().focus().redo().run(),
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.divider {
  background-color: rgba(#fff, 0.25);
  height: 1.25rem;
  margin-left: 0.5rem;
  margin-right: 0.75rem;
  width: 1px;
}
</style>
