// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";

export default createVuetify({
  theme: {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    defaultTheme: localStorage.colorTheme || "light",
    themes: {
      light: {
        dark: false,
        colors: {
          background: "#F9F7F7",
          surface: "#DBE2EF",
          primary: "#716595",
          secondary: "#3F72AF",
          third: "#91b1c7",
          error: "#B00020",
          info: "#2196F3",
          success: "#4CAF50",
          warning: "#fb5800",
          border: "#F5F5F5",
          customPrimary: "#FFF",
        },
      },
      dark: {
        dark: true,
        colors: {
          // background: "#161220",
          // surface: "#18122B",
          // primary: "#F5F5F5",
          secondary: "#2b224b",
          // // secondary: "#635985",
          // third: "#716595",
          // border: "#4F4F52",
          background: "#0e0810",
          surface: "#0B0718",
          // surface: "#1c1c23",
          primary: "#716595",
          // secondary: "#2c004f",
          // secondary: "#635985",
          third: "#FFFFFF",
          border: "#4F4F52",
          customPrimary: "#141020",
        },
      },
    },
  },
});
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
