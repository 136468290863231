import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import keyControl from "../views/keyControl.vue";
import planControl from "../views/planControl.vue";
import adminPanel from "../views/adminPanel.vue";
import profileView from "../views/profileView.vue";
import signalsControl from "../views/signalsControl.vue";
import marketplace from "../views/marketplaceView.vue";
import { useUserStore } from "@/store/userStore";
import { RolesType } from "@/api/user.types";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/passrestore",
    name: "passrestore",
    component: () => import("@/views/restorePassword.vue"),
  },
  {
    path: "/keycontrol",
    name: "Key control",
    component: keyControl,
    meta: { requiresAuth: true },
  },
  {
    path: "/plancontrol",
    name: "Plan control",
    component: planControl,
    meta: { requiresAuth: true },
  },
  {
    path: "/admin",
    name: "Admin panel",
    component: adminPanel,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: "/profie",
    name: "Profile view",
    component: profileView,
    meta: { requiresAuth: true },
  },
  {
    path: "/signals",
    name: "Signals control",
    component: signalsControl,
    meta: { requiresAuth: true },
  },
  {
    path: "/marketplace",
    name: "Marketplace",
    component: marketplace,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const userStore = useUserStore();
    if (!userStore.isLoggedIn) {
      next({
        path: "/",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAdmin)) {
    const userStore = useUserStore();

    if (!userStore.user?.role.includes(RolesType.ADMINISTRATOR)) {
      next({
        path: "/",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});
export default router;
