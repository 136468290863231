import axios from "axios";
import { GridItem, PlanTags } from "@/api/subscription.types";
import { SimpleSuccess } from "@/api/auth.types";

class SubscriptionApi {
  async getPrices(): Promise<GridItem[]> {
    const res = await axios.get<GridItem[]>("/subscription/prices");
    return res.data;
  }

  async subscribe(tag: PlanTags, duration?: number) {
    const res = await axios.post<SimpleSuccess>("/subscription/subscribe", {
      tag,
      duration,
    });
    return res.data;
  }

  async increaseKeyLimit(tag: PlanTags) {
    const res = await axios.post<SimpleSuccess>("/subscription/key/increase", {
      tag,
    });
    return res.data;
  }
}

export default new SubscriptionApi();
