import axios from "axios";
import {
  CreateNewKey,
  CreateNewKeyRes,
  EditKey,
  Key,
  KeyCheckRes,
} from "@/api/keys.types";
import { SimpleSuccess } from "@/api/auth.types";

class KeysApi {
  async getKeys(): Promise<Key[]> {
    const res = await axios.get<Key[]>("/keys");
    return res.data;
  }
  async addNewKey(key: CreateNewKey): Promise<CreateNewKeyRes> {
    const res = await axios.post<CreateNewKeyRes>("/keys/add", key);
    return res.data;
  }
  async removeKey(id: string): Promise<SimpleSuccess> {
    const res = await axios.post<SimpleSuccess>("/keys/remove", {
      id: id,
    });
    return res.data;
  }
  async checkKey(key: CreateNewKey): Promise<KeyCheckRes> {
    const res = await axios.post<KeyCheckRes>("/keys/check", key);
    return res.data;
  }
  async editKey(edit: EditKey): Promise<SimpleSuccess> {
    const res = await axios.post<SimpleSuccess>("/keys/edit", edit);
    return res.data;
  }
}

export default new KeysApi();
