export interface SignalResponse {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  description: string;
  market: Markets;
  linkedConfig: string;
  price: string;
  likes: number;
  subscribers: number;
}

export enum Markets {
  SPOT = "SPOT",
  FUTURES = "FUTURES",
}

export interface MySignalResponse extends SignalResponse {
  id: string;
  isSelling: boolean;
}

export interface CreateSignalRequest {
  name: string;
  description: string;
  market: Markets;
  isSelling: boolean;
  price: number;
}

export interface EditSignalRequest {
  name?: string;
  description?: string;
  id: string;
  isSelling?: boolean;
  price?: number;
}

export interface BuySignalBody {
  id: string;
  duration: number;
}

export interface RenewSignalBody {
  id: string;
  duration: number;
}

export interface BindKeyBody {
  apiKeyId: string;
  purchaseId: string;
}

export interface PurchasedSignal {
  createdAt: string;
  updatedAt: string;
  id: string;
  price: number;
  validTo: string;
  signalProps: SignalProps;
}
export interface SignalProps {
  linkedKey: string;
  signalName: string;
  signalDescription: string;
}
