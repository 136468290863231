export interface SignalRouter {
  createdAt: string;
  id: string;
  name: string;
  description: string;
  isSelling: boolean;
  market: Markets;
  price: number;
  subscribers: number;
}

export enum Markets {
  SPOT = "SPOT",
  FUTURES = "FUTURES",
}

export enum Sides {
  LONG = "LONG",
  SHORT = "SHORT",
}

export interface CreateRouter {
  name: string;
  description: string;
  market: Markets;
}

export interface EditRouter {
  id: string;
  name: string;
  description: string;
  isSelling: boolean;
}

export interface SignalsFromRouter {
  time: string;
  pair: string;
  side: Sides;
  timeframe: string;
  price: number;
  volume: number;
}

export interface AvailableRouter {
  createdAt: string;
  id: string;
  name: string;
  description: string;
  market: Markets;
}

export interface BindKeyRequest {
  routerId: string;
  apiKeyId: string;
}

export interface GetMySubscriptionRes {
  id: string;
  routers: string[];
  keyId: string;
}
