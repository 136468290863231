import { defineStore } from "pinia";
import { Tokens } from "@/api/auth.types";
import auth from "@/api/auth";
import axios, { AxiosError } from "axios";
import jwt_decode from "jwt-decode";
import { User } from "@/api/user.types";
import user from "@/api/user";

export const useUserStore = defineStore("user", {
  state: (): State => ({
    tokens: { access_token: "", refresh_token: "" },
    jwtUser: {
      sub: "",
      email: "",
      rules: [],
    },
    user: undefined,
    isLoggedIn: false,
  }),
  getters: {
    // doubleCount: (state) => state.count * 2,
  },
  actions: {
    async updateTokens(tokens?: Tokens) {
      return new Promise((r) => {
        if (tokens !== undefined) {
          this.parseTokens(tokens).then(r);
        }
        auth
          .refresh()
          .then(async (res) => {
            await this.parseTokens(res.data);
            this.getUser();
            r(undefined);
          })
          .catch((e: AxiosError) => {
            if (e.response?.status === 403) {
              this.isLoggedIn = false;
              localStorage.setItem("rt", "");
              this.jwtUser = {
                sub: "",
                email: "",
                rules: [],
              };
            }
            console.error(e);
          });
      });
    },
    async logout() {
      auth.logout().then(() => {
        this.isLoggedIn = false;
        localStorage.setItem("rt", "");
        this.jwtUser = {
          sub: "",
          email: "",
          rules: [],
        };
      });
    },
    async parseTokens(tokens: Tokens) {
      this.isLoggedIn = true;
      this.jwtUser = jwt_decode(tokens.access_token) as JwtPayload;
      this.tokens = tokens;
      localStorage.setItem("rt", tokens.refresh_token);
      axios.defaults.headers.common = {
        Authorization: `Bearer ${tokens.access_token}`,
      };
    },
    getUser() {
      user.getInfo().then((x) => {
        this.user = x;
      });
    },
  },
});

interface State {
  tokens: Tokens;
  jwtUser: JwtPayload;
  isLoggedIn: boolean;
  user: undefined | User;
}

interface JwtPayload {
  sub: string;
  email: string;
  rules: string[];
}
