import axios, { AxiosResponse } from "axios";
import {
  RegisterResponse,
  RestoreConfirm,
  RestoreRequest,
  SimpleSuccess,
  Tokens,
} from "@/api/auth.types";

class AuthApi {
  async register(
    email: string,
    password: string,
    captcha: string
  ): Promise<RegisterResponse> {
    const res = await axios.post<{ link: string }>(
      "/auth/local/signup",
      {
        email: email,
        password: password,
      },
      {
        headers: {
          recaptcha: captcha,
        },
      }
    );
    return res.data;
  }

  async login(
    email: string,
    password: string,
    captcha: string
  ): Promise<Tokens> {
    const res = await axios.post<{
      access_token: string;
      refresh_token: string;
    }>(
      "/auth/local/signin",
      {
        email: email,
        password: password,
      },
      {
        headers: {
          recaptcha: captcha,
        },
      }
    );
    return res.data;
  }

  async verify(
    link: string,
    code: number
  ): Promise<{ success: boolean; tokens?: Tokens }> {
    const res = await axios.post<{
      success: boolean;
      tokens?: Tokens;
    }>("/auth/verify", {
      link: link,
      code: code,
    });
    return res.data;
  }

  async logout(): Promise<AxiosResponse> {
    return axios.get("/auth/logout");
  }

  async refresh(): Promise<AxiosResponse> {
    const token = localStorage.getItem("rt");
    return axios.get<Tokens>("/auth/refresh", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async requestRestore(
    restore: RestoreRequest,
    captcha: string
  ): Promise<SimpleSuccess> {
    const res = await axios.post<{ success: boolean }>(
      "/auth/local/restore/send",
      restore,
      {
        headers: {
          recaptcha: captcha,
        },
      }
    );
    return res.data;
  }

  async verifyRestore(
    restore: RestoreConfirm,
    captcha: string
  ): Promise<SimpleSuccess> {
    const res = await axios.post<{ success: boolean }>(
      "/auth/local/restore/verify",
      restore,
      {
        headers: {
          recaptcha: captcha,
        },
      }
    );
    return res.data;
  }
}

export default new AuthApi();
