import axios from "axios";
import { RawPayment, SudoUser } from "@/api/sudo.types";
import { SimpleSuccess } from "@/api/auth.types";
import { Key } from "@/api/keys.types";
import { RolesType } from "@/api/user.types";
import { Payment } from "@/api/payments.types";

class Sudo {
  async getAllUsers() {
    const res = await axios.get<SudoUser[]>("/sudo/fetch/all-users");
    return res.data;
  }

  async getUserStats() {
    const res = await axios.get<
      { email: string; lastTrade: number; trades: number }[]
    >("/sudo/fetch/users-stats");
    return res.data;
  }

  async getPayments() {
    const res = await axios.get<RawPayment[]>("/sudo/fetch/payments");
    return res.data;
  }

  async getUserKeys(id: string) {
    const res = await axios.post<Key[]>("/sudo/fetch/user-keys", { id });
    return res.data;
  }
  async deleteKey(id: string) {
    const res = await axios.post("/sudo/delete/key", { id });
    return res.data;
  }
  async editBalance(id: string, value: number) {
    const res = await axios.post<SimpleSuccess>("/sudo/edit/balance", {
      id,
      value,
    });
    return res.data;
  }
  async editExpireDate(id: string, value: number) {
    const res = await axios.post<SimpleSuccess>("/sudo/edit/expiration-date", {
      id,
      value,
    });
    return res.data;
  }
  async editRole(id: string, role: RolesType) {
    const res = await axios.post<SimpleSuccess>("/sudo/edit/role", {
      id,
      role,
    });
    return res.data;
  }

  async verifyUser(id: string) {
    const res = await axios.post<SimpleSuccess>("/sudo/edit/verify", id);
    return res.data;
  }
  async editKeyLimit(id: string, value: number) {
    const res = await axios.post<SimpleSuccess>("/sudo/edit/keys-limit", {
      id,
      value,
    });
    return res.data;
  }
  async getUserPayments(id: string) {
    const res = await axios.post<Payment[]>("/sudo/fetch/user-payments", {
      id,
    });
    return res.data;
  }

  async getAllTesters() {
    const res = await axios.get<SudoUser[]>("/sudo/fetch/testers");
    return res.data;
  }

  async setTester(email: string) {
    const res = await axios.post<SimpleSuccess>("/sudo/edit/tester", {
      email,
    });
    return res.data;
  }

  async deleteTester(email: string) {
    const res = await axios.post<SimpleSuccess>("/sudo/delete/tester", {
      email,
    });
    return res.data;
  }
}

export default new Sudo();
