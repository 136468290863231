export enum PaymentStatus {
  COMPLETED = "completed",
  EXPIRED = "expired",
  FAILED = "failed",
  CREATED = "created",
  WAITING = "waiting",
  CONFIRMING = "confirming",
  SENDING = "sending",
  FINISHED = "finished",
  PARTIALLY_PAID = "partially_paid",
}

export enum PaymentSource {
  REPUBLISH = "republish",
  TRANSACTION = "transaction",
  ADMIN = "admin",
  PURCHASE = "purchase",
}

export interface Payment {
  amount: number;
  id: string;
  status: PaymentStatus;
  time: Date;
  invoiceUrl: string;
  comment: string;
}

export enum SupportedCurrencies {
  USDT_TRC20 = "USDTTRC20",
  USDT_BSC = "USDTBSC",
  BNB_BSC = "BNBBSC",
  BUSD_BSC = "BUSDBSC",
  TRX = "TRX",
}

export const SupportedCoinsRecord: Record<string, SupportedCurrencies> = {
  "USDT (TRC20)": SupportedCurrencies.USDT_TRC20,
  "USDT (BEP20)": SupportedCurrencies.USDT_BSC,
  "BNB (BEP20)": SupportedCurrencies.BNB_BSC,
  "BUSD (BEP20)": SupportedCurrencies.BUSD_BSC,
  "TRX (TRX)": SupportedCurrencies.TRX,
};

export interface PaymentInfo {
  amount: number;
  currency: SupportedCurrencies;
}

export interface NewPayment {
  payAddress: string;
  paymentId: string;
  validUntil: Date;
  payAmount: number;
  network: string;
}
